import axios, { AxiosResponse } from "axios";
import { API_URL } from "../constants";
import { UserInfo } from "../contexts/UserInfoContext";
import {
  GetChatHistoryRes,
  GetSessionRes,
  PostMessageRes,
} from "../models/Chat";
import { checkSessionExpired } from "./Auth";

export async function postChatMessage(
  message: string,
  chatId: string,
  accessToken: string
): Promise<PostMessageRes | undefined> {
  if (!checkSessionExpired(accessToken)) {
    const requestBody = {
      question: message,
      chatId,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const response: AxiosResponse = await axios.post(
        `${API_URL}chat/response`,
        requestBody,
        config
      );
      // check to see if the response indicates an error
      if ("errorType" in response.data) {
        return undefined;
      } else {
        return response.data;
      }
    } catch (error) {
      return undefined;
    }
  } else return undefined;
}

export async function getChatSession(
  userInfo: UserInfo,
  chatId: string
): Promise<GetSessionRes | undefined> {
  if (!checkSessionExpired(userInfo.accessToken)) {
    const headers = {
      Authorization: `Bearer ${userInfo.accessToken}`,
    };
    try {
      const response: AxiosResponse = await axios.get(
        `${API_URL}chat/${chatId}`,
        {
          headers: headers,
        }
      );

      return response.data;
    } catch (error) {
      return undefined;
    }
  } else return undefined;
}

export async function deleteChatSession(
  chatId: string,
  accessToken: string
): Promise<any> {
  if (!checkSessionExpired(accessToken)) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    try {
      const response: AxiosResponse = await axios.delete(
        `${API_URL}chat/${chatId}`,
        {
          headers: headers,
        }
      );

      return response.data;
    } catch (error) {
      return undefined;
    }
  } else return undefined;
}

export async function putResponseRating(
  chatId: string,
  exchangeId: string,
  rating: number | null,
  accessToken?: string
): Promise<any> {
  if (!checkSessionExpired(accessToken)) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const request = { rating };
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${API_URL}chat/${chatId}/exchange/${exchangeId}/rating`,
          request,
          { headers: headers }
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  } else return undefined;
}

export async function getChatHistory(
  userInfo: UserInfo
): Promise<GetChatHistoryRes | undefined> {
  if (!checkSessionExpired(userInfo.accessToken)) {
    const headers = {
      Authorization: `Bearer ${userInfo.accessToken}`,
    };
    try {
      const response = await axios.get(`${API_URL}chats`, {
        headers: headers,
      });

      return response.data;
    } catch (error) {
      return undefined;
    }
  } else return undefined;
}
