import { Avatar, Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import { ThreeDots } from "react-loader-spinner";
import botIcon from "../../src/public/bot.png";
import { useCustomThemeContext } from "../lib/contexts/ThemeContext";

export default function AnswerLoadingState() {
  const { custom } = useCustomThemeContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Avatar
        variant="square"
        sx={{
          bgcolor: blue[900],
          marginRight: "40px",
          fontSize: "0.6em",
          borderRadius: "5px",
          padding: "2px",
        }}
      >
        <img
          src={botIcon}
          alt="botIcon"
          style={{ width: "25px", height: "25px" }}
        />
      </Avatar>

      <ThreeDots
        height="30"
        width="30"
        radius="3"
        color={custom.palette.primary.dark}
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </Box>
  );
}
