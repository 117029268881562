import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Alert } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import LinkCreator from "./linkCreator";

interface SourcesAccordionProps {
  sources: string;
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `white`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandCircleDownOutlinedIcon
        transform={"rotate(-180deg)"}
        color={"primary"}
        sx={{ marginLeft: 0 }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light" ? "rgba(0, 0, 0, 0)" : "rgba(0, 0, 0, 0)",
  flexDirection: "row-reverse",
  paddingLeft: 0,
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: 0,
  paddingTop: 0,
}));

export default function SourcesAccordion(props: SourcesAccordionProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Accordion
      elevation={0}
      expanded={expanded === "sources"}
      onChange={handleChange("sources")}
    >
      <AccordionSummary
        aria-controls="sources-content"
        id="sources-header"
        color={"primary"}
      >
        <Typography color={"#1976D2"}>Sources</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {props.sources.split("\n").map((line, index) => (
            <Alert
              icon={false}
              key={index}
              sx={{ marginBottom: "1vh" }}
              severity="info"
            >
              {<LinkCreator line={line} />}
            </Alert>
          ))}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
