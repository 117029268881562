/************************************************************************************
  Customize the application by changing the values in this file
*************************************************************************************/

export const config = {
  styledText: "C H A T B O T",
  applicationName: "Chatbot",
  companyName: "Glovis",
  applicationSummary: 
    "Glovis' exclusive AI tool, designed to provide answers to a diverse" +
    "range of inquiries about Glovis and its work. Feel free to begin by " +
    "asking a question or choosing from the sample questions provided below.",
}

export const exampleQuestions = [
  "What is the return to work policy?",
  "SQL: How many GPQMS related tickets are closed?",
  "SQL: List all the open requests for Megan Sow",
]
