import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";

export default function Footer() {
  return (
    <Box>
      <AppBar
        position="static"
        sx={{
          background: "rgba(0, 0, 0, 0.87)",
          height: "4vh",
        }}
      >
      </AppBar>
    </Box>
  );
}
