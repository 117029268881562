import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import photoIcon from "../../src/public/iPhoneIllustration.svg";
import { config } from "../config";
import QuestionCards from "./questionCards";

interface WelcomeMessageProps {
  chatId: string;
  handleNewChat?: any;
  handleSubmit?: any;
  setSelectedQ?: any;
  setCurrentMessage?: any;
}

export default function WelcomeMessage(props: WelcomeMessageProps) {
  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: "1%",
        marginTop: "10vh",
        height: "100%",
      }}
    >
      <img
        src={photoIcon}
        alt="phone"
        style={{ width: "15vw", height: "35vh", marginRight: "1%" }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "3%" }}>
        <Typography
          sx={{ fontWeight: "bold", fontSize: "22px" }}
          variant="button"
        >
          Welcome to {config.applicationName}
        </Typography>

        <Typography sx={{ fontSize: "20px" }}>
          {config.applicationSummary}
        </Typography>
        <>
          <Typography
            variant="subtitle1"
            sx={{ marginTop: "3%", fontWeight: "bold" }}
          >
            Some things you can ask
          </Typography>
          <QuestionCards handleSubmit={props.handleSubmit} />
        </>
      </Box>
    </Box>
  );
}
