import { createContext, useContext } from "react";
import { ChatSession, Message } from "../interfaces/Chat";

interface ChatSessionsContextType {
  chatSessions: ChatSession[];
  updateSelectedChatSession: (chatSessionId: string) => void;
  addNewChatSession: (question?: string) => string;
  updateNewChatSession: (question: string) => void;
  setChatSessions: any;
  selectedChatSession: string | null | undefined;
  messages: Message[];
  setMessages: any;
  noMsgReset: React.MutableRefObject<boolean>;
}

const ChatSessionsContext = createContext<ChatSessionsContextType | undefined>(
  undefined
);

export function useChatSessionsContext() {
  const context = useContext(ChatSessionsContext);
  if (context === undefined) {
    throw new Error();
  }
  return context;
}

export default ChatSessionsContext;
