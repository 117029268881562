import jwt from "jwt-decode";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { checkValidToken } from "../api/Auth";
import awsExports from "../aws-exports";
import UserInfoContext, { UserInfo } from "./UserInfoContext";

export function UserInfoContextProvider() {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [render, setRender] = useState<Boolean>(false);

  useEffect(() => {
    // check if userInfo is in localStorage (i.e. user is already signed in and their token isn't expired)
    const lastAuthUser = localStorage.getItem(
      `CognitoIdentityServiceProvider.${awsExports.USER_POOL_APP_CLIENT_ID}.LastAuthUser`
    );
    if (lastAuthUser) {
      const accessToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.${awsExports.USER_POOL_APP_CLIENT_ID}.${lastAuthUser}.idToken`
      );
      if (accessToken && checkValidToken(accessToken)) {
        const decodedToken: any = jwt(accessToken);
        const email = decodedToken["email"];
        const userId = decodedToken["sub"];
        const groups = decodedToken["cognito:groups"];
        setUserInfo({ userId, accessToken, email, groups });
      }
    }
    setRender(true);
  }, []);

  const updateUserInfo = (newValue: UserInfo) => {
    setUserInfo(newValue);
  };

  return (
    <UserInfoContext.Provider value={{ userInfo, updateUserInfo }}>
      {render && <Outlet />}
    </UserInfoContext.Provider>
  );
}
