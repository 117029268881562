import { Box, Drawer } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DrawerItems from "../../components/drawerItems";
import Footer from "../../components/footer";
import { MessageWindow } from "../../components/messageWindow";
import { logout } from "../../lib/api/Auth";
import { useChatSessionsContext } from "../../lib/contexts/ChatSessionsContext";
import { useUserInfoContext } from "../../lib/contexts/UserInfoContext";
import { useChat } from "../../lib/hooks/UseChat";
import { Message } from "../../lib/interfaces/Chat";
import { Auth } from "../auth/auth";

export function Chat() {
  const {
    getChatMessages,
    removeChatSession,
    getUpdatedSelectedChatSession,
    getHistory,
  } = useChat();
  const { userInfo } = useUserInfoContext();
  const {
    chatSessions,
    updateSelectedChatSession,
    setChatSessions,
    selectedChatSession,
    setMessages,
    noMsgReset,
  } = useChatSessionsContext();

  const [render, setRender] = useState(false);
  const [deleteChatId, setDeleteChatId] = useState<string>("");
  const isLoading = useRef(false);

  useEffect(() => {
    // Wait 2 seconds for authentication
    const delay = setTimeout(() => {
      setRender(true);
    }, 2000);
    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    userInfo &&
      getHistory(userInfo).then((res) => {
        if (res) setChatSessions(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (render && !userInfo) {
      setRender(false);
      logout();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render, userInfo]);
 
  useEffect(() => {
    if (selectedChatSession && !noMsgReset.current) {
      const chatSession = chatSessions.find((session) => {
        return session.chatInfo.chatId === selectedChatSession;
      });
      if (
        chatSession &&
        chatSession.chatInfo.chatTitle !== "Untitled" &&
        userInfo
      ) {
        getChatMessages(userInfo, chatSession.chatInfo.chatId).then(
          (messages) => {
            if (messages) setMessages(messages);
            else setMessages([]);
          }
        );
      } else setMessages([]);
    } else noMsgReset.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatSession, userInfo]);

  const deleteChatSession = (chatId: string) => {
    setDeleteChatId(chatId);
    // delete chat session in the backend
    userInfo &&
      removeChatSession(userInfo, chatId).then((res) => {
        setDeleteChatId("");
        if (res) {
          //if the user is deleting the currently selected chat session, we need to set a new selectedChatSession
          if (chatId === selectedChatSession) {
            const newlySelectedChatSessionId = getUpdatedSelectedChatSession(
              chatId,
              chatSessions
            );
            // if the user deleted the last chat session, a new chat session will be created when the user sends the next question
            // so the previous messages from the deleted chat session need to be cleared
            if (newlySelectedChatSessionId === "") setMessages([]);
            updateSelectedChatSession(newlySelectedChatSessionId);
          }
          // update UI if removing chat session in the backend was successful
          setChatSessions(
            chatSessions.filter((item) => item.chatInfo.chatId !== chatId)
          );
        } else {
          alert(
            "Sorry, the chat session could not be deleted. Please try again."
          );
        }
      });
  };

  const addMessage = (
    message: string,
    fromUser: boolean,
    exchangeId: string
  ) => {
    setMessages((prevMessages: Message[]) => [
      ...prevMessages,
      { message, fromUser, exchangeId },
    ]);
  };

  return (
    <>
      {render && (
        userInfo ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "89vh",
              }}
            >
              <Drawer
                variant="permanent"
                open={true}
                sx={{ width: "15%", overflowY: "auto" }}
                PaperProps={{
                  sx: {
                    overflowY: "auto",
                    height: "89%",
                    top: "7vh",
                    width: "15%",
                    float: "left",
                    position: "absolute",
                  },
                }}
              >
                <DrawerItems
                  handleDelete={deleteChatSession}
                  deleteChatId={deleteChatId}
                  isLoading={isLoading}
                />
              </Drawer>
              <MessageWindow
                chatId={selectedChatSession ?? ""}
                addMessage={addMessage}
                isLoading={isLoading}
              />
            </Box>
            <Footer />
          </>
        ) : (
          <Auth />
        )
      )}
    </>
  );
}
