import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { MutableRefObject, useEffect, useState } from "react";
import { useChatSessionsContext } from "../lib/contexts/ChatSessionsContext";
import { useCustomThemeContext } from "../lib/contexts/ThemeContext";
import { ChatSession } from "../lib/interfaces/Chat";

interface DrawerItemsProps {
  handleDelete: (chatId: string) => void;
  deleteChatId: string;
  isLoading: MutableRefObject<boolean>;
}
export default function DrawerItems({
  handleDelete,
  deleteChatId,
  isLoading,
}: DrawerItemsProps) {
  const { custom } = useCustomThemeContext();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });
  const {
    chatSessions,
    updateSelectedChatSession,
    addNewChatSession,
    selectedChatSession,
  } = useChatSessionsContext();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sessionsGroupedByMonths = chatSessions.reduce<
    Record<string, ChatSession[]>
  >((groupedSessions, chatSession) => {
    const month = chatSession.month;
    if (!groupedSessions[month]) {
      groupedSessions[month] = [];
    }
    groupedSessions[month].push(chatSession);
    return groupedSessions;
  }, {});

  return (
    <Box sx={{ marginLeft: "5%" }}>
      <Button
        variant="outlined"
        startIcon={<AddRoundedIcon />}
        sx={{
          width: "90%",
          marginTop: "5%",
          marginBottom: "5%",
        }}
        onClick={() => {
          isLoading.current = false;
          addNewChatSession();
        }}
      >
        New Chat
      </Button>
      {windowSize.width > 700 ? (
        <List>
          {Object.keys(sessionsGroupedByMonths).map((month) => (
            <Box key={month}>
              <Typography
                variant="button"
                sx={{
                  color: custom.palette.primary.light,
                  fontWeight: "bold",
                  letterSpacing: "0.08em",
                  fontSize: "0.9rem",
                }}
              >
                {month}
              </Typography>
              {sessionsGroupedByMonths[month].map((chatSession) => (
                <ListItem key={chatSession.chatInfo.chatId} disablePadding>
                  <ListItemButton
                    sx={{
                      "& .MuiSvgIcon-fontSizeMedium": {
                        display: "none",
                      },
                      "&:hover": {
                        "& .MuiSvgIcon-fontSizeMedium": {
                          display: "inline-block",
                        },
                      },
                    }}
                    key={chatSession.chatInfo.chatId}
                    selected={
                      chatSession.chatInfo.chatId === selectedChatSession
                    }
                    onClick={() => {
                      isLoading.current = false;
                      updateSelectedChatSession(chatSession.chatInfo.chatId);
                    }}
                  >
                    <ListItemText
                      primary={chatSession.chatInfo.chatTitle}
                      primaryTypographyProps={{
                        style: {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        },
                      }}
                    />
                    <Box sx={{ position: "relative" }}>
                      <ListItemIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(chatSession.chatInfo.chatId);
                        }}
                      >
                        {deleteChatId !== chatSession.chatInfo.chatId ? (
                          <DeleteOutlineIcon />
                        ) : (
                          <CircularProgress
                            size={16}
                            sx={{
                              color: custom.palette.primary.light,
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-10px",
                              marginLeft: "-24px",
                            }}
                          />
                        )}
                      </ListItemIcon>
                    </Box>
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
          ))}
        </List>
      ) : (
        <List>
          {chatSessions.map((chatSession) => (
            <ListItem key={chatSession.chatInfo.chatId} disablePadding>
              <ListItemButton>
                <ListItemText
                  primary={chatSession.chatInfo.chatTitle}
                  primaryTypographyProps={{
                    style: {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
}
