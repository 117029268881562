import { useUserInfoContext } from "../../lib/contexts/UserInfoContext";
import { useEffect } from "react";
import { Hub } from "aws-amplify";
import { Outlet } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

export function Auth() {
  const { updateUserInfo } = useUserInfoContext();
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          var userInfo = {
            userId: data.signInUserSession.idToken.payload["cognito:username"],
            accessToken: data.signInUserSession.idToken.jwtToken,
            email: data.signInUserSession.idToken.payload.email,
            groups: data.signInUserSession.idToken.payload["cognito:groups"],
          };
          updateUserInfo({ ...userInfo });
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Error", data);
          break;
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Authenticator>
      <Outlet />
    </Authenticator>
  );
}
