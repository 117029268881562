import { Amplify } from "aws-amplify";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Header from "./components/header";
import awsExports from "./lib/aws-exports";
import { AUTH_DOMAIN, WEB_URL } from "./lib/constants";
import { ChatSessionsContextProvider } from "./lib/contexts/ChatSessionsProvider";
import { CustomThemeContextProvider } from "./lib/contexts/ThemeProvider";
import { UserInfoContextProvider } from "./lib/contexts/UserInfoProvider";
import { Auth } from "./pages/auth/auth";
import { Chat } from "./pages/chat/chat";
import Unauthorized from "./pages/unauthorized/unauthorized";

export interface IAccessCreds {
  accessToken: string;
  refreshToken: string;
}

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    oauth: {
      domain: AUTH_DOMAIN,
      scope: ["email", "openid"],
      redirectSignIn: WEB_URL,
      redirectSignOut: WEB_URL,
      responseType: "code",
    },
  },
});

const Root = () => {
  return (
    <div>
      <Routes>
        <Route element={<CustomThemeContextProvider />}>
          <Route element={<UserInfoContextProvider />}>
            <Route element={<ChatSessionsContextProvider />}>
              <Route element={<Header />}>
                <Route element={<Auth />}>
                  <Route path="/" element={<Chat />} />
                  <Route path="/chat" element={<Chat />} />
                </Route>
                <Route path="/unauthorized" element={<Unauthorized />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}

export default App;
