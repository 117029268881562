import { Box } from "@mui/material";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useChatSessionsContext } from "../lib/contexts/ChatSessionsContext";
import { useCustomThemeContext } from "../lib/contexts/ThemeContext";
import { useUserInfoContext } from "../lib/contexts/UserInfoContext";
import { AnswerFormatter } from "../lib/helpers/answerFormatter";
import { useChat } from "../lib/hooks/UseChat";
import AnswerLoadingState from "./answerLoadingState";
import ChatBubble from "./chatBubble";
import InputArea from "./inputArea";
import WelcomeMessage from "./welcomeMessage";

interface MessageWindowProps {
  chatId: string;
  addMessage: (
    currentMessage: string,
    fromUser: boolean,
    exchangeId: string
  ) => void;
  isLoading: MutableRefObject<boolean>;
}

export const MessageWindow = ({
  chatId,
  addMessage,
  isLoading,
}: MessageWindowProps) => {
  const { postMessage } = useChat();
  const {
    chatSessions,
    addNewChatSession,
    updateNewChatSession,
    selectedChatSession,
    messages,
    noMsgReset,
  } = useChatSessionsContext();
  const { custom } = useCustomThemeContext();
  const { userInfo } = useUserInfoContext();

  const endOfMessages = useRef<null | HTMLDivElement>(null); // variable that holds the reference to the last message in the chat
  const [currentMessage, setCurrentMessage] = useState<string>(""); // handling state of current message being typed by user

  //sets the text in the textbox as the current message
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMessage(event.target.value);
  };

  const handleSubmit = (event?: React.FormEvent, selectedQuestion?: string) => {
    event?.preventDefault();
    let newChatId;

    if (selectedChatSession) {
      if (
        (currentMessage !== "" || selectedQuestion) &&
        messages.length === 0
      ) {
        // update the chat session title if it is a new chat session
        updateNewChatSession(
          selectedQuestion ? selectedQuestion : currentMessage
        );
      }
    } else if (!selectedQuestion && currentMessage) { // the user submitted a question from the homepage
      noMsgReset.current = true;
      newChatId = addNewChatSession(currentMessage);
    } else { // a prefilled question from homepage was selected
      noMsgReset.current = true;
      newChatId = addNewChatSession(selectedQuestion);
    }

    isLoading.current = true;
    addMessage(selectedQuestion ? selectedQuestion : currentMessage, true, "");

    userInfo &&
      postMessage(
        selectedQuestion ? selectedQuestion : currentMessage,
        userInfo,
        newChatId ? newChatId : chatId
      ).then((res) => {
        if (isLoading.current) {
          const formattedResponse = res
            ? AnswerFormatter(res.answer, res.source_documents)
            : "We are unable to find relevant information at this point. Please try again later.";
          // adding the bot's answer to the messages array
          const botMessage = formattedResponse;
          addMessage(botMessage, false, res?.exchangeId ?? "");
          isLoading.current = false;
        }
      });
    setCurrentMessage(""); // resetting currentMessage variable so that it can then hold the new message entered by the user
  };

  // scrolling down to the current/latest message when messages array changes
  useEffect(() => {
    if (endOfMessages.current) {
      endOfMessages.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <Box
      sx={{
        background: custom.palette.primary.main,
        paddingLeft: "3%",
        display: "flex",
        paddingBottom: "1%",
        flexDirection: "column",
        width: "-webkit-fill-available",
      }}
    >
      {/* if the array of messages is empty (if no messages have been sent yet), the phone image and intro text will be rendered */}
      {chatSessions.length === 0 ||
      (messages.length === 0 &&
        !isLoading.current) ? (
        <WelcomeMessage
          chatId={chatId}
          handleSubmit={handleSubmit}
          setCurrentMessage={setCurrentMessage}
        />
      ) : (
        <Box sx={{ margin: "auto", width: "100%", overflowY: "auto" }}>
          {messages.map((message, index) => (
            <ChatBubble
              key={index}
              message={message}
              index={index}
              previousMessage={messages[index - 1]}
              chatId={chatId}
            />
          ))}
          <div ref={endOfMessages} />

          {/** if the bot's answer is still loading... */}
          {isLoading.current && <AnswerLoadingState />}
        </Box>
      )}
      <InputArea
        currentMessage={currentMessage}
        disabled={isLoading.current || currentMessage === ""}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
      />
    </Box>
  );
};
