import {
  postChatMessage,
  getChatSession as getSession,
  deleteChatSession,
  putResponseRating,
  getChatHistory,
} from "../api/Chat";
import { AnswerFormatter } from "../helpers/answerFormatter";
import { ChatSession, Exchange, Message } from "../interfaces/Chat";
import _ from "lodash";
import { GetChatHistoryRes } from "../models/Chat";
import { UserInfo } from "../contexts/UserInfoContext";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";

export const useChat = () => {
  const postMessage = async (
    message: string,
    userInfo: UserInfo,
    chatId: string
  ) => {
    const result = await postChatMessage(message, chatId, userInfo.accessToken);
    return result;
  };

  const getChatSession = async (userInfo: UserInfo, chatId: string) => {
    const res = await getSession(userInfo, chatId);
    return res;
  };

  const getChatMessages = async (userInfo: UserInfo, chatId: string) => {
    const res = await getChatSession(userInfo, chatId);
    if (res) {
      let messages: Message[] = [];
      const conversation = res.conversation;
      for (var exchangeId in conversation) {
        if (conversation.hasOwnProperty(exchangeId)) {
          const exchange: Exchange = conversation[exchangeId];
          messages.push({
            message: exchange.question,
            fromUser: true,
            exchangeId: exchange.exchangeId,
          });
          messages.push({
            message: AnswerFormatter(exchange.response, exchange.sources),
            fromUser: false,
            exchangeId: exchange.exchangeId,
          });
        }
      }
      return messages;
    }
    return undefined;
  };

  const createNewChatSession = (title?: string) => {
    const newChatSessionId = uuidv4();
    const currentDate = new Date();
    const currentMonthYear = currentDate.toLocaleString("default", {
      month: "long",
      year: "numeric",
    });
    const newChatSession: ChatSession = {
      chatInfo: {
        chatId: newChatSessionId,
        chatTitle: title ? title : "Untitled",
        timestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        conversation: [],
      },
      month: currentMonthYear,
    };
    return newChatSession;
  };

  const removeChatSession = async (userInfo: UserInfo, chatId: string) => {
    const res = await deleteChatSession(chatId, userInfo.accessToken);
    return res;
  };

  const putRating = async (
    accessToken: string | undefined,
    chatId: string,
    exchangeId: string,
    rating: number | null
  ) => {
    const result = await putResponseRating(
      chatId,
      exchangeId,
      rating,
      accessToken
    );
    return result;
  };

  const getUpdatedSelectedChatSession = (
    chatId: string,
    chatSessions: ChatSession[]
  ) => {
    let newlySelectedChatSessionId = "";
    if (chatSessions.length === 1) return newlySelectedChatSessionId;
    const deletedChatSessionIndex = _.findIndex(
      chatSessions,
      function (chatSession) {
        return chatSession.chatInfo.chatId === chatId;
      }
    );
    if (deletedChatSessionIndex === chatSessions.length - 1) {
      newlySelectedChatSessionId =
        chatSessions[deletedChatSessionIndex - 1].chatInfo.chatId;
    } else
      newlySelectedChatSessionId =
        chatSessions[deletedChatSessionIndex + 1].chatInfo.chatId;

    return newlySelectedChatSessionId;
  };

  const getHistory = async (userInfo: UserInfo) => {
    const result = await getChatHistory(userInfo);
    if (!result) return result;
    const chatSessionArray = getChatSessionArray(result);
    return chatSessionArray;
  };

  const getChatSessionArray = (result: GetChatHistoryRes) => {
    const chatSessionsArray: ChatSession[] = [];
    if (_.isEmpty(result["monthlyChatSessions"])) {
      return chatSessionsArray;
    } else {
      const monthlyChatSessions = result["monthlyChatSessions"];
      for (var monthYear in monthlyChatSessions) {
        if (monthlyChatSessions.hasOwnProperty(monthYear)) {
          const sessions = monthlyChatSessions[monthYear];
          sessions.map((session) => {
            const chatSession: ChatSession = {
              month: monthYear,
              chatInfo: session,
            };
            chatSessionsArray.push(chatSession);
          });
        }
      }
      return chatSessionsArray;
    }
  };

  return {
    postMessage,
    getChatSession,
    getChatMessages,
    createNewChatSession,
    removeChatSession,
    putRating,
    getUpdatedSelectedChatSession,
    getHistory,
  };
};
