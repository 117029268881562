import { Box, Card, CardActionArea, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import { exampleQuestions } from "../config";

interface QuestionCardsProps {
  handleSubmit: any;
}

const QuestionCards: React.FC<QuestionCardsProps> = ({ handleSubmit }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} marginTop={"1%"}>
      {exampleQuestions.map((question, index) => (
        <Card
          key={index}
          sx={{ marginRight: "1%", width: "40%" }}
          onClick={(event) => {
            handleSubmit(event, question);
          }}
        >
          <CardActionArea sx={{ height: "100%" }}>
            <CardContent>
              <Typography gutterBottom variant="body1">
                {question}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      ))}
    </Box>
  );
};

export default QuestionCards;
