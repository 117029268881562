import SendIcon from "@mui/icons-material/Send";
import { IconButton, InputBase, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { useCustomThemeContext } from "../lib/contexts/ThemeContext";

interface InputAreaProps {
  currentMessage: string;
  disabled: boolean;
  handleSubmit: any;
  handleInputChange: any;
}

export default function InputArea(props: InputAreaProps) {
  const { custom } = useCustomThemeContext();
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginTop: "1%",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      {/* Textfield section */}
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "90%",
          borderRadius: "30px",
          height: "5vh",
        }}
        // calls handleSubmit function when user either hits "Enter" or clicks on the submit icon button
        onSubmit={props.handleSubmit}
      >
        <InputBase
          value={props.currentMessage}
          onChange={props.handleInputChange} // when user enters text, handleInputChange is called to update currentMessage
          sx={{ width: "100%", paddingLeft: "15px" }}
          placeholder="Send a message"
          inputProps={{ "aria-label": "Send a message" }}
        />

        <IconButton
          sx={{ p: "5%" }}
          aria-label="directions"
          type="submit"
          disabled={props.disabled}
        >
          <SendIcon
            sx={{
              color: props.disabled
                ? "rgba(0, 0, 0, 0.26)"
                : custom.palette.secondary.light,
            }}
          />
        </IconButton>
      </Paper>
    </Box>
  );
}
