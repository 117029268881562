import { useRef, useState } from "react";
import ChatSessionsContext from "./ChatSessionsContext";
import { Outlet } from "react-router-dom";
import { ChatSession, Message } from "../interfaces/Chat";
import { useChat } from "../hooks/UseChat";

export function ChatSessionsContextProvider() {
  const [chatSessions, setChatSessions] = useState<ChatSession[]>([]);
  const [selectedChatSession, setSelectedChatSession] = useState<
    string | null
  >();
  const [messages, setMessages] = useState<Message[]>([]);
  const noMsgReset = useRef(false);

  const { createNewChatSession } = useChat();

  const updateSelectedChatSession = (chatSessionId: string) => {
    setSelectedChatSession(chatSessionId);
  };

  const addNewChatSession = (question?: string) => {
    const newChatSession = createNewChatSession(question);

    setChatSessions((prevChatSessions) => [
      newChatSession,
      ...prevChatSessions,
    ]);

    if (!noMsgReset.current) setMessages([]);
    setSelectedChatSession(newChatSession.chatInfo.chatId);
    return newChatSession.chatInfo.chatId;
  };

  const updateNewChatSession = (question: string) => {
    // update the chat session title if it is a new chat session
    const updatedChatSessions = [...chatSessions];
    updatedChatSessions[0] = {
      ...updatedChatSessions[0],
      chatInfo: {
        ...updatedChatSessions[0].chatInfo,
        chatTitle: question,
      },
    };
    setChatSessions(updatedChatSessions);
  };

  return (
    <ChatSessionsContext.Provider
      value={{
        chatSessions,
        updateSelectedChatSession,
        addNewChatSession,
        updateNewChatSession,
        setChatSessions,
        selectedChatSession,
        messages,
        setMessages,
        noMsgReset,
      }}
    >
      <Outlet />
    </ChatSessionsContext.Provider>
  );
}
