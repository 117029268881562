import Linkify from "linkify-react";
import { Link } from "react-router-dom";

interface LinkCreatorProps {
  line: string;
}

export default function LinkCreator(props: LinkCreatorProps) {
  return (
    <Linkify
      options={{
        render: {
          url: ({ attributes, content }) => {
            return (
              <a {...attributes} target="_blank">
                {content}
              </a>
            );
          },
          mention: ({ attributes, content }) => {
            const { href, ...props } = attributes;
            return (
              <Link to={href} {...props}>
                {content}
              </Link>
            );
          },
        },
      }}
    >
      {props.line}
    </Linkify>
  );
}
