import {
  Avatar,
} from "@mui/material";
import Box from "@mui/material/Box";
import { blue, teal } from "@mui/material/colors";
import botIcon from "../../src/public/bot.png";
import { useCustomThemeContext } from "../lib/contexts/ThemeContext";
import { Message } from "../lib/interfaces/Chat";
import LinkCreator from "./linkCreator";
import SourcesAccordion from "./sourcesAccordion";

interface ChatBubbleProps {
  index: number;
  message: Message;
  previousMessage: Message;
  chatId: string;
}

export default function ChatBubble(props: ChatBubbleProps) {
  const { custom } = useCustomThemeContext();
  const containsSources = props.message.message.includes("Sources:");
  const sources =
    containsSources && props.message.message.split("Sources:")[1].trim();
  const answer = containsSources
    ? props.message.message.split("Sources:")[0].trim()
    : props.message.message;
  /* Uncomment when Rate Answer feature is needed 
  const [value, setValue] = useState<number | null>(2);
  const [rate, setRate] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [ratingSubmitted, setRatingSubmitted] = useState<boolean>();
  const [fail, setFail] = useState<boolean>();

  const { putRating } = useChat();
  const { userInfo } = useUserInfoContext();
  const { chatId, message } = props;

   function handleSubmit() {
    setDisabled(true);

    const { exchangeId } = message;

    putRating(userInfo?.accessToken, chatId, exchangeId, value)
      .then(() => {
        setRatingSubmitted(true);
        setFail(false);
      })
      .catch(() => {
        // API request failed, handle error
        setRatingSubmitted(false);
        setFail(true);
        setDisabled(false);
      });
  }
*/

  return (
    <Box
      key={props.index}
      style={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        textAlign: "left",
        padding: "20px 0",
        width: "95%",
      }}
    >
      {/** If the message is from the user, render their avatar. Otherwise, render FA's avatar */}
      {props.message.fromUser ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Avatar
            variant="square"
            sx={{
              bgcolor: teal[800],
              marginRight: "20px",
              fontSize: "0.7em",
              borderRadius: "5px",
              padding: "2px",
              fontWeight: 550,
            }}
          >
            USER
          </Avatar>
        </Box>
      ) : (
        <Avatar
          variant="square"
          sx={{
            bgcolor: blue[900],
            marginRight: "20px",
            fontSize: "0.6em",
            borderRadius: "5px",
            padding: "2px",
          }}
        >
          <img
            src={botIcon}
            alt="botIcon"
            style={{ width: "25px", height: "25px" }}
          />
        </Avatar>
      )}

      <Box
        sx={{
          width: "100%",
          padding: "20px",
          marginRight: "30px",
          borderRadius: "10px",
          background: props.message.fromUser //making the background of the message white if it's from FA's bot
            ? custom.palette.primary.main
            : "white",
        }}
      >
        <Box sx={{ marginBottom: "1vh" }}>{<LinkCreator line={answer} />}</Box>

        {!props.message.fromUser &&
          sources &&
          props.message.message.includes("Sources:") && (
            <SourcesAccordion sources={sources} />
          )}
        {/* Uncomment when Rate Answer feature is needed */}
        {/* {!props.message.fromUser && (
          <Box>
            {rate ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "left",
                }}
              >
                {!ratingSubmitted && message.exchangeId && (
                  <>
                    <Rating
                      name="answer-rating"
                      defaultValue={0.0}
                      precision={0.5}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                        setDisabled(false);
                      }}
                    />
                    <Button
                      variant="outlined"
                      sx={{ width: "1vh", fontSize: "0.7em", marginTop: "1vh" }}
                      onClick={handleSubmit}
                      disabled={disabled}
                    >
                      Submit
                    </Button>
                  </>
                )}
                {ratingSubmitted && !fail && (
                  <Typography sx={{ color: "grey", fontSize: "0.8em" }}>
                    Thank you for your feedback!
                  </Typography>
                )}
                {!ratingSubmitted && fail && (
                  <Typography sx={{ color: "grey", fontSize: "0.8em" }}>
                    Error submitting feedback. Please try again.
                  </Typography>
                )}
              </Box>
            ) : (
              <Button
                onClick={() => {
                  setRate(true);
                }}
                sx={{ fontSize: "0.7em" }}
              >
                Rate Answer
              </Button>
            )}
          </Box>
        )} */}
      </Box>
    </Box>
  );
}
